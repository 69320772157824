import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"

class IndexPage extends React.Component {
  render() {
    const siteTitle = "Dave Lewis"
    const { data } = this.props
    
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Home"
          keywords={[`developer`, `amateur radio`]}
        />
        <Img 
          fluid={data.photo.childImageSharp.fluid} 
          alt="ProgNET 2018"
        />
        <h1>
          Shwmae{" "}
          <span role="img" aria-label="wave emoji">
            👋
          </span>
        </h1>
        <p>I'm a software developer and amateur radio enthusiast from Swansea, Wales.</p>
        <p>
          Sometimes I talk at meetups and conferences.
        </p>
        <Link to="/blog/">
          <Button marginTop="35px">Go to Blog</Button>
        </Link>
      </Layout>
    )
  }
}

export const query = graphql`
  query PhotoQuery {
    photo: file(absolutePath: { regex: "/ProgNET_2018_Talk.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 800)
        {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IndexPage
